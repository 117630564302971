<template>
	<div class="error-page">
		<img src="~bh-mod/images/404.svg" alt="404 Error" style="width: 80vw; height: 50vh;">
		<h1>Oops! Page Not Found</h1>
		<p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
		</p>
		<router-link to="/">
			<a-button type="primary" size="large">
				Go back to Home Page
			</a-button>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'ErrorPage'
}
</script>

<style scoped>
.error-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: 90vh;
}

.error-page img {
	max-width: 100%;
	height: auto;
	margin: 20px 0px;
}

.error-page h1 {
	font-size: 36px;
	margin-bottom: 20px;
}

.error-page p {
	font-size: 18px;
	margin-bottom: 20px;
}
</style>
